import { useState, useEffect, useContext, createContext } from 'react'
import axios from 'axios';


const AuthContext = createContext()
const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    name: null,
    email: null,
    isAdmin: null,
    token: ""
  });

  axios.defaults.headers.common['Authorization'] = auth?.token
  //Zadrzavanje korisnika na sajtu
  useEffect(() => {
    const data = localStorage.getItem('auth')
    if (data) {
      const parseData = JSON.parse(data)
      setAuth({
        ...auth,
        isAdmin: parseData.isAdmin,
        email: parseData.email,
        name: parseData.name,
        token: parseData.token
      })
    }
  }, [])

  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext)

export { useAuth, AuthProvider }