import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useAuth } from "../context/auth";
import axios from "axios";
import "../App.css";
import Card from "./Card";
import Swal from 'sweetalert2';
//import './assets/sass/light-bootstrap-dashboard.css?v=1.2.0';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useNavigate } from 'react-router-dom';


let data;
data = [
  { name: "AlT1", value: 700, selected: false, alt: 'Sigurnosna vaga' },
  { name: "AlT2", value: 325, selected: false, alt: 'Vaga za merenje' },
  { name: "AlT3", value: 500, selected: false, alt: 'Baza spremna za EAS deaktivator' },
  { name: "AlT4", value: 400, selected: false, alt: 'Nadogradnja ekrana sa 15" na 21,5"' },
  { name: "AlT5", value: 350, selected: false, alt: 'Inteligentno svetlo sa kamerom' },
  { name: "AcT1", value: 975, selected: false, alt: 'Sigurnosna vaga' },
  { name: "AcT2", value: 400, selected: false, alt: 'Nadogradnja ekrana sa 15" na 21,5"' },
  { name: "AcT3", value: 350, selected: false, alt: 'Inteligentno svetlo sa kamerom' },
  { name: "AcT4", value: 188.5, selected: false, alt: 'Nefiskalni štampač RP-700' },
  { name: "AcT5", value: 132.5, selected: false, alt: 'Nefiskalni štampač SEIKO' },
  { name: "AcT6", value: 4050, selected: false, alt: 'Plaćanje gotovinom' },
];
function MainPage() {
  const [auth] = useAuth();

  const hiddenLogOut = () => {
    console.log("local storage clear")
    localStorage.clear();
    // this part reloads page, and since local storagge is empty it sends user to login page
    console.log("navigate part")
    window.location.reload();
  }

  //Logout finction
  const logout = () => {
    Swal.fire({
      title: "Odjavljivanje",
      text: "Da li ste sigurni da želite da se odjavite?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Ne",
      confirmButtonText: "Da"
    }).then(response => {
      if(response.isConfirmed){
 // user data stored in local storage (cookie)
    // removes all data from local storage and reloads page
    console.log("local storage clear")
    localStorage.clear();
    // this part reloads page, and since local storagge is empty it sends user to login page
    console.log("navigate part")
    window.location.reload();
    // navigate('/');
      }
    })
  }
  
  const [name, setName ] = useState('');
  const [sugestedPrice, setSugestedPrice] = useState(0); //sugested price ?
  const [discount, setDiscount] = useState(0);
  const [printer, setPrinter] = useState(0);
  const [email, setEmail] = useState(auth.email);
  const [nadgledanjeDa, setNadgledanjeDa] = useState(false);
  const [nadgledanjeNe, setNadgledanjeNe] = useState(false);
  const [brojObjekata, setBrojObjekata] = useState(0);
  const navigate = useNavigate(); //za promu stranice 


  useEffect(() => {
    if (auth.email != null) {
      axios
        .post(`/api/users/getCurrentUserData`, { email: auth.email })
        .then((response) => {
          console.log(response.data);
          setName(response.data.name);
          setSugestedPrice(response.data.sugestedPrice);
          setDiscount(response.data.discount);
          setEmail(response.data.email);
          console.log(discount, sugestedPrice);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      navigate('/')
    }
  }, [auth]);

  const [noOfAlfred, setNoOfAlfred] = useState(0); //For counting how many Alfred devices
  const [sumAlfred, setSumAlfred] = useState(0);

  const [noOfAce, setNoOfAce] = useState(0); // for counting how many Ace devices
  const [sumAce, setSumAce] = useState(0);

  const [selectPrinterA, setSelectPrinterA] = useState(false);
  const [selectPrinterB, setSelectPrinterB] = useState(false);
  const [opcijaPlacanja, setOpcijaPlacanja] = useState(""); //za softver
  const [opcijaIntegracije, setOpcijaIntegracije] = useState(""); //za integraciju

  const switchCheckBox = (e) => {
    if (e.target.name === "opcijaStampacaA" && e.target.checked === true) {
      setSelectPrinterA(true);
      setSelectPrinterB(false);
      data[8].selected = true;
      data[9].selected = false;

    } else if (e.target.name === "opcijaStampacaB" && e.target.checked === true) {
      setSelectPrinterA(false);
      setSelectPrinterB(true);
      data[8].selected = false;
      data[9].selected = true;
    } else {
      setSelectPrinterA(false);
      setSelectPrinterB(false);
      data[8].selected = false;
      data[9].selected = false;
      setPrinter(0);
    }
  };

  const switchNadogradnja = (e) => {
    if (e.target.name === "Da" && e.target.value === 'on') {
      setNadgledanjeDa(true);
      setNadgledanjeNe(false);
    } else if (e.target.name === "Ne" && e.target.value === 'on') {
      setNadgledanjeDa(false);
      setNadgledanjeNe(true);
      setBrojObjekata(0);
    }
  };
  // Funkcija za resetovanje vrednosti nakon slanja mejla
  const resetFields = () => {
    setEmail(auth.email);
    setNoOfAlfred(0);
    setNoOfAce(0);
    setDiscount(0);
    setOpcijaPlacanja("");
    setOpcijaIntegracije("");
    setBrojObjekata(0);
  };

  const mailSend = () => {

    if ((noOfAlfred + noOfAce) < 0 || (opcijaPlacanja == "") || (opcijaIntegracije == "")) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Forma nije kompletno popunjena!',
        text: 'Molimo Vas da popunite sva polja pre slanja upita.',
        showConfirmButton: false,
        timer: 3000
      });
      // Prekidamo izvršenje funkcije jer podaci nisu kompletno popunjeni
      return;
    }

    const podaciZaSlanje = {
      name: name,
      email: email,
      noOfAlfred: noOfAlfred,
      noOfAce: noOfAce,
      discount: discount,
      options: data,
      opcijaPlacanja: opcijaPlacanja,
      brojObjekata: brojObjekata,
      opcijaIntegracije: opcijaIntegracije,
      sugestedPrice: sugestedPrice
    }
    console.log(podaciZaSlanje)

    axios.post('/api/users/mailSend', podaciZaSlanje)
      .then(response => {
        resetFields();
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Upit je poslat!',
          showConfirmButton: false,
          timer: 3000
        })
        hiddenLogOut();
        // Ovde pozovi ako hoces da izloguje korisnika odmah nakon sto ga obavesti da je forma poslata,
        // Ako hoces mozes i neko dugme negde da uglavis
      })
      .catch(err => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Upit nije poslat!',
          showConfirmButton: false,
          timer: 3000
        })
      })
  }

  function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
  }

  const [noOfDevices, setNoOfDevices] = useState(0);

  useEffect(() => {
    calculateAlfred(noOfAlfred);
    calculateAce(noOfAce);
    setNoOfDevices(noOfAlfred + noOfAce);
  }, [noOfAlfred, noOfAce, printer]);

  // Calculate sum for Alfred thing
  const calculateAlfred = () => {
    let tempSum = 0;
    for (let i = 0; i <= 4; i++) {
      if (data[i].selected === true) {
        tempSum += data[i].value;
      }
    }
    setSumAlfred(((tempSum + 3450) * discount) * noOfAlfred);
    //jel to? Da probaj , samo uradi isto i za 
  };

  //Kalkulacija za Ace plus dodaci
  const calculateAce = () => {
    let tempSum = 0;
    for (let i = 5; i <= 10; i++) {
      if (data[i].selected === true) {
        tempSum += data[i].value;
      }
    }
    setSumAce((tempSum + 7800) * discount * noOfAce);
  };

  const changeJsonData = (name) => {
    let csum = 0;

    for (let j = 0; j < data.length; j++) {
      if (data[j].name === name) {
        data[j].selected = !data[j].selected;
      }
      if (data[j].selected === true) {
        csum += data[j].value;
      }
    }
    console.log(csum, "csum");
    calculateAlfred(noOfAlfred);
    calculateAce(noOfAce);
  };

  const nadgledanjeInput = nadgledanjeDa === true ?
    (
      <div>
        Unesite broj objekata:
        <input
          onChange={(e) => {
            setBrojObjekata(e.target.value);
          }}
          style={{ width: "20%" }}
          type="number"
          placeholder="Broj objekata"
        />
      </div>
    ) : (<div></div>);

  const hasAce = noOfAce > 0 ?
    (
      <tr>
        <td style={{ fontSize: "11px" }}>ACE</td>
        <td style={{ fontSize: "11px" }}>{noOfAce}</td>
        <td style={{ fontSize: "11px" }}>{sumAce / noOfAce}</td>
        <td style={{ fontSize: "11px" }}>{roundToTwo(sumAce)}€</td>
      </tr>
    ) : (<tr></tr>);
  const hasAlfred = noOfAlfred > 0 ?
    (
      <tr>
        <td style={{ fontSize: "11px" }}>Alfred</td>
        <td style={{ fontSize: "11px" }}>{noOfAlfred}</td>
        <td style={{ fontSize: "11px" }}>{sumAlfred / noOfAlfred}</td>
        <td style={{ fontSize: "11px" }}>{roundToTwo(sumAlfred)}€</td>
      </tr>
    ) : (<tr></tr>);

  const install = noOfDevices > 0 ?
    (
      <tr>
        <td style={{ fontSize: "11px" }}><b>Instalacija</b></td>
        <td style={{ fontSize: "11px" }}><b>{noOfDevices}</b></td>
        <td style={{ fontSize: "11px" }}>{roundToTwo((400 * discount))}</td>
        <td style={{ fontSize: "11px" }}><b>+{roundToTwo(noOfDevices * (400 * discount))}€</b></td>
      </tr>
    ) : (<tr></tr>)

  const godisnje = opcijaPlacanja == "Godisnje placanje" && noOfDevices > 0 ?
    (
      <tr>
        <td style={{ fontSize: "11px" }}>Godišnje</td>
        <td style={{ fontSize: "11px" }}>{noOfDevices}</td>
        <td style={{ fontSize: "11px" }}>{roundToTwo(noOfDevices * (1190 * discount))}€</td>
      </tr>
    ) : (<tr></tr>)

  const mesecno = opcijaPlacanja == "Mesecno placanje" && noOfDevices > 0 ?
    (
      <tr>
        <td style={{ fontSize: "11px" }}>Mesečno </td>
        <td style={{ fontSize: "11px" }}>{noOfDevices}</td>
        <td style={{ fontSize: "11px" }}>{roundToTwo(noOfDevices * (80 * discount))}€</td>
      </tr>
    ) : (<tr></tr>);

  let cenaSoftvera = 0;
  if (opcijaPlacanja == "Godisnje placanje") {
    cenaSoftvera = (180 * discount)
  } else {
    cenaSoftvera = (8 * discount)
  }
//Jana testira u lokalu ?
  const softwareCosts = brojObjekata > 0 && opcijaPlacanja != "" ? (
    <tr>
      <td style={{ fontSize: "11px" }}>Softver za nadgledanje</td>
      <td style={{ fontSize: "11px" }}>{brojObjekata}</td>
      <td style={{ fontSize: "11px" }}>{roundToTwo(brojObjekata * cenaSoftvera)}€</td>
    </tr>
  ) : (<tr></tr>)

  const integracija1 = opcijaIntegracije == "pojedinici" && noOfDevices > 0 ?
    (
      <tr>
        <td style={{ fontSize: "11px" }}>Integracija po jedinici</td>
        <td style={{ fontSize: "11px" }}>
          {opcijaIntegracije === "pojedinici" && (noOfAce + noOfAlfred) > 3 ? roundToTwo((noOfAce + noOfAlfred) * (1500 * discount)) + "€" : ""}
          {opcijaIntegracije === "pojedinici" && (noOfAce + noOfAlfred) <= 3 ? roundToTwo((4500 * discount)) + "€" : ""}
        </td>
      </tr>

    ) : (<tr></tr>)

  const integracija2 = opcijaIntegracije == "jednokratno" && noOfDevices > 0 ?
    (
      <tr>
        <td style={{ fontSize: "11px" }}>Jednokratka integracija</td>
        <td style={{ fontSize: "11px" }}>{sugestedPrice},00€</td>
      </tr>

    ) : (<tr></tr>)

  const sideTable = (

    <Col
      md={3}
      style={{
        position: "fixed",
        top: "4%",
        right: "0px",
        paddingLeft: "25px",
      }}
    >
      <Card
        content={
          <div style={{ padding: "3px", maxHeight: "80vh", position: "relative" }}>
            <h3 style={{ position: "sticky", top: 0, background: "#F2F2F2", zIndex: 1 }}>Kalkulator</h3>
            <div style={{ overflowY: 'scroll', maxHeight: "calc(80vh - 150px)" }}>
              <div class="container shadow py-2">
                <div class="table-responsive">
                  <table class="table accordion">
                    <tbody>
                      <tr data-bs-toggle="collapse">
                        <td
                          colSpan={4}
                          style={{ background: "#F2F2F2", fontSize: "14px" }}
                        >
                          <b>HERDVER</b>
                        </td>
                      </tr>
                      <tr>
                        <th scope="col" style={{ fontSize: "10px" }}>
                          <b>SCO{" "}</b>
                        </th>
                        <th scope="col" style={{ fontSize: "10px" }}>
                          <b>Kom.</b>
                        </th>
                        <th scope="col" style={{ fontSize: "10px" }}>
                          <b>Cena</b>
                        </th>
                        <th scope="col" style={{ fontSize: "10px" }}>
                          <b>Ukupno</b>
                        </th>
                      </tr>
                      {hasAce}
                      {hasAlfred}
                      {install}
                      <tr>
                        <th scope="col" style={{ fontSize: "10px" }}></th>
                        <th scope="col" style={{ fontSize: "10px" }}></th>
                        <th scope="col" style={{ fontSize: "10px" }}></th>
                        <th scope="col" style={{ fontSize: "10px" }}><b>
                          {noOfDevices > 0 ? `${roundToTwo((sumAce + sumAlfred + (noOfDevices * (250 * discount))))}€` : ""}
                        </b></th>

                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
              <br />
              <div class="container shadow py-2">
                <div class="table-responsive">
                  <table class="table accordion">
                    <tbody>
                      <tr data-bs-toggle="collapse">
                        <td
                          colSpan={4}
                          style={{ background: "#F2F2F2", fontSize: "14px" }}
                        >
                          <b>SOFTVER</b>
                        </td>
                      </tr>
                      <tr>
                        <th scope="col" style={{ fontSize: "10px" }}>
                          <b>Opcija za placanje</b>
                        </th>
                        <th scope="col" style={{ fontSize: "10px" }}>
                          <b>Kolicina</b>
                        </th>
                        <th scope="col" style={{ fontSize: "10px" }}>
                          <b>Ukupno</b>
                        </th>
                      </tr>
                      {godisnje}
                      {mesecno}
                      {softwareCosts}
                      <tr>
                        <th scope="col" style={{ fontSize: "10px" }}></th>
                        <th scope="col" style={{ fontSize: "10px" }}></th>
                        <th scope="col" style={{ fontSize: "10px" }}>
                          <b>
                            {opcijaPlacanja === "Godisnje placanje" && noOfDevices > 0 ? `${roundToTwo((noOfDevices * (1190 * discount)) + (brojObjekata * cenaSoftvera))}€` : ""}
                            {opcijaPlacanja === "Mesecno placanje" && noOfDevices > 0 ? `${roundToTwo((noOfDevices * (80 * discount)) + (brojObjekata * cenaSoftvera))}€` : ""}
                          </b>
                        </th>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
              <br />
              <div class="container shadow py-2">
                <div class="table-responsive">
                  <table class="table accordion">
                    <tbody>
                      <tr data-bs-toggle="collapse">
                        <td
                          colSpan={4}
                          style={{ background: "#F2F2F2", fontSize: "14px" }}
                        >
                          <b>INTEGRACIJA</b>
                        </td>
                      </tr>
                      <tr>
                        <th scope="col" style={{ fontSize: "10px" }}>
                          <b>Opcija integracije</b>
                        </th>
                        <th scope="col" style={{ fontSize: "10px" }}>
                          <b>Ukupno</b>
                        </th>
                      </tr>
                      {integracija1}
                      {integracija2}
                      <tr>
                        <th scope="col" style={{ fontSize: "10px" }}>
                        </th>
                        <th scope="col" style={{ fontSize: "10px" }}>
                          <b>
                            {opcijaIntegracije === "pojedinici" && (noOfAce + noOfAlfred) <= 3 && noOfDevices > 0 ? `${roundToTwo(4500 * discount)}€` : ""}
                            {opcijaIntegracije === "pojedinici" && (noOfAce + noOfAlfred) > 3 ? roundToTwo((noOfAce + noOfAlfred) * (1500 * discount)) + "€" : ""}
                            {opcijaIntegracije === "jednokratno" && noOfDevices > 0 ? (sugestedPrice) + "€" : ""}</b>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <br />
            <div style={{ position: "sticky", bottom: 0, width: "100%", padding: "10px", zIndex: 1 }}>
              <a
                href="#"
                class="btn btn-primary btn-lg active"
                role="button"
                aria-pressed="true"
              > Ukupno:{" "}
                {roundToTwo((sumAce + sumAlfred + (noOfDevices * (250 * discount))) +
                  (opcijaPlacanja === "Godisnje placanje" && noOfDevices > 0 ? ((noOfDevices * (1190 * discount)) + (brojObjekata * cenaSoftvera)) : 0) +
                  (opcijaPlacanja === "Mesecno placanje" && noOfDevices > 0 ? ((noOfDevices * (80 * discount)) + (brojObjekata * cenaSoftvera)) : 0) +
                  (opcijaIntegracije === "pojedinici" && (noOfAce + noOfAlfred) > 3 && noOfDevices > 0 ? ((noOfAce + noOfAlfred) * (1500 * discount)) : 0) +
                  (opcijaIntegracije === "pojedinici" && (noOfAce + noOfAlfred) <= 3 && noOfDevices > 0 ? (4500 * discount) : 0) +
                  (opcijaIntegracije === "jednokratno" && noOfDevices > 0 ? roundToTwo(sugestedPrice) : 0)
                )}€
              </a>
            </div>
          </div>
        }
      />
      <br />
      <button type="button" onClick={() => { mailSend() }} class="btn btn-success" style={{ width: "40%" }}><b>Potvrdi</b></button>&emsp;
      <button type='button' className="btn btn-danger" style={{ width: "40%"}} onClick={() => { logout() }}><b>Odjavi se</b></button>
    </Col>
  );
  return (
    <div className="App" style={{ backgroundColor: "#f2f2f2" }}>
      <div className="content" style={{ fontSize: "13px" }}>
        <Container fluid>
          <Col md={9}>
            <Row style={{ paddingLeft: "10px" }}>
              <Card
                content={
                  <div style={{ padding: "8px" }}>
                    <h1 style={{ background: "#f2f2f2" }}>Hardware</h1>
                    <p
                      class="font-weight-light"
                      style={{
                        paddingRight: "8%",
                        paddingLeft: "8%",
                        textAlign: "justify",
                      }}
                    >
                      {" "}
                      Hardver samouslužne kase predstavlja kombinaciju uređaja
                      upakovanih u jedinstvenu celinu spremnu za postavku u
                      maloprodajnim radnjama. U zavisnosti od odabranog modela i
                      dodatnih opcija, hardver samouslužne kase čine: all-in-one
                      računar, sigurnosna vaga, vaga za merenje, inteligentno
                      svetlo, kamera, bar kod skener, štampač, EAS deaktivator
                      (obično obezbeđuje korisnik), terminal za plaćanje (obično
                      obezbeđuje korisnik), kućište, postolje, prostor za
                      odlaganje korpe, prostor za pakovanje, keš mašina.
                    </p>
                    <br />
                    <Container fluid>
                      <Row>
                        <Col md={6}>
                          <div class="container shadow py-2">
                            <div class="table-responsive">
                              <table class="table accordion">
                                <thead>
                                  <tr
                                    data-bs-toggle="collapse"
                                    data-bs-target="#n1"
                                  >
                                    <th
                                      style={{
                                        background: "#f2f2f2",
                                        fontSize: "17px",
                                        textAlign: "center",
                                      }}
                                      colSpan={2}
                                      scope="row"
                                    >
                                      Self-checkout | Alfred SCO &emsp;
                                      <i class="bi bi-chevron-down" />
                                    </th>
                                  </tr>
                                  <tr
                                    class="collapse accordion-collapse"
                                    id="n1"
                                    data-bs-parent=".table"
                                  >
                                    <td
                                      colspan="5"
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "justify",
                                      }}
                                    >
                                      {" "}
                                      Model samouslužne kase Alfred predstavlja
                                      manji SCO uređaj pogodan za manje i
                                      srednje maloprodajne radnje, odnosno za
                                      kupovine od 5 do 15 artikala. Moguća je
                                      postavka dva Alfred uređaja na mesto jedne
                                      tradicionalne kase. Osnovni model obuhvata
                                      all-in-one touch računar Audrey A5 sa
                                      ekranom 15,6", 8GB RAM i 128GB SSD
                                      memorije, standardno svetlo, 2D vertikalni
                                      skener, štampač, spremnost za terminal za
                                      plaćanje. Ukoliko želite osnovni model bez
                                      dodataka, dovoljno je da upišete broj u
                                      okviru polja za količinu, a ukoliko želite
                                      neki od ponuđenih dodataka, potrebno je da
                                      označite odabrane dodatke klikom na
                                      kvadrat koji se nalazi ispred naziva
                                      dodatne opcije, i zatim da unesete broj u
                                      polju za količinu.{" "}
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr data-bs-toggle="collapse">
                                    <td style={{ textAlign: "left" }}>
                                      Sigurnosna vaga
                                    </td>
                                    <td style={{ width: "30%" }}>
                                      <input
                                        type="checkbox"
                                        onClick={() => changeJsonData("AlT1")}
                                      />
                                    </td>
                                  </tr>
                                  <tr data-bs-toggle="collapse">
                                    <td style={{ textAlign: "left" }}>
                                      Vaga za merenje
                                    </td>
                                    <td style={{ width: "30%" }}>
                                      {" "}
                                      <input
                                        type="checkbox"
                                        onChange={() => changeJsonData("AlT2")}
                                      />
                                    </td>
                                  </tr>
                                  <tr data-bs-toggle="collapse">
                                    <td style={{ textAlign: "left" }}>
                                      Baza spremna za EAS deaktivator
                                    </td>
                                    <td style={{ width: "30%" }}>
                                      <input
                                        type="checkbox"
                                        onChange={() => changeJsonData("AlT3")}
                                      />
                                    </td>
                                  </tr>
                                  <tr data-bs-toggle="collapse">
                                    <td style={{ textAlign: "left" }}>
                                      Nadogradnja ekrana sa 15" na 21,5"
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        onChange={() => changeJsonData("AlT4")}
                                      />
                                    </td>
                                  </tr>
                                  <tr data-bs-toggle="collapse">
                                    <td style={{ textAlign: "left" }}>
                                      Inteligentno svetlo sa kamerom
                                    </td>
                                    <td>
                                      {" "}
                                      <input
                                        type="checkbox"
                                        onChange={() => changeJsonData("AlT5")}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {" "}
                                      <b style={{ fontSize: "15px" }}>
                                        Unesite broj komada:
                                      </b>
                                    </td>
                                    <td style={{ width: "30%" }}>
                                      <input
                                        style={{ width: "100%" }}
                                        type="number"
                                        placeholder="kom."
                                        min={0}
                                        onChange={(e) => {
                                          setNoOfAlfred(Number(e.target.value));
                                          console.log(noOfAlfred, noOfAce);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>

                        <br />
                        <br />
                        <br />

                        <Col md={6}>
                          <div class="container shadow py-2">
                            <div class="table-responsive">
                              <table class="table accordion">
                                <thead>
                                  <tr
                                    data-bs-toggle="collapse"
                                    data-bs-target="#n2"
                                  >
                                    <th
                                      style={{
                                        background: "#f2f2f2",
                                        fontSize: "17px",
                                        textAlign: "center",
                                      }}
                                      colSpan={2}
                                      scope="row"
                                    >
                                      Self-checkout | ACE SCO &emsp;
                                      <i class="bi bi-chevron-down" />
                                    </th>
                                  </tr>
                                  <tr
                                    class="collapse accordion-collapse"
                                    id="n2"
                                    data-bs-parent=".table"
                                  >
                                    <td
                                      colspan="5"
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "justify",
                                      }}
                                    >
                                      {" "}
                                      Model samouslužne kase Ace predstavlja
                                      veći, stand alone, SCO uređaj pogodan za
                                      velike samouslužne radnje, odnosno za veće
                                      kupovine. Ovaj uređaj ima svoje postolje
                                      za prostorom za odlaganje korpe i
                                      prostorom za pakovanje, te zauzima više
                                      prostora u odnosu na Alfred model. Osnovni
                                      model obuhvata all-in-one touch računar
                                      Audrey A5 sa ekranom 15,6", 8GB RAM i
                                      128GB SSD memorije, standardne LED
                                      indikatore, skener, vagu za merenje,
                                      prostor za pakovanje bez sigurnosne vage,
                                      držače za štampač i terminal za plaćanje.
                                      Ukoliko želite osnovni model bez dodataka,
                                      dovoljno je da upišete broj u okviru polja
                                      za količinu, a ukoliko želite neki od
                                      ponuđenih dodataka, potrebno je da
                                      označite odabrane dodatke klikom na
                                      kvadrat koji se nalazi ispred naziva
                                      dodatne opcije, i zatim da unesete broj u
                                      polju za količinu.{" "}
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr data-bs-toggle="collapse">
                                    <td style={{ textAlign: "left" }}>
                                      Sigurnosna vaga
                                    </td>
                                    <td style={{ width: "30%" }}>
                                      <input
                                        type="checkbox"
                                        onChange={() => changeJsonData("AcT1")}
                                      />
                                    </td>
                                  </tr>
                                  <tr data-bs-toggle="collapse">
                                    <td style={{ textAlign: "left" }}>
                                      Nadogradnja ekrana sa 15" na 21,5"
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        onChange={() => changeJsonData("AcT2")}
                                      />
                                    </td>
                                  </tr>
                                  <tr data-bs-toggle="collapse">
                                    <td style={{ textAlign: "left" }}>
                                      Inteligentno svetlo sa kamerom
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        onChange={() => changeJsonData("AcT3")}
                                      />
                                    </td>
                                  </tr>
                                  <tr data-bs-toggle="collapse">
                                    <td style={{ textAlign: "left" }}>
                                      <p>Nefiskalni štampač RP-700 ili</p>
                                      <p>Nefiskalni štampač SEIKO </p>
                                    </td>
                                    <td>
                                      <p>
                                        <input
                                          checked={selectPrinterA}
                                          type="checkbox"
                                          name="opcijaStampacaA"
                                          onChange={(e) => {
                                            setPrinter(188.5);
                                            switchCheckBox(e);
                                          }}
                                        />
                                      </p>
                                      <p>
                                        <input
                                          checked={selectPrinterB}
                                          type="checkbox"
                                          name="opcijaStampacaB"
                                          onChange={(e) => {
                                            setPrinter(132.5);
                                            switchCheckBox(e);
                                          }}
                                        />
                                      </p>
                                    </td>
                                  </tr>
                                  <tr data-bs-toggle="collapse">
                                    <td style={{ textAlign: "left" }}>
                                      Plaćanje gotovinom
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        onChange={() => changeJsonData("AcT6")}
                                      />
                                    </td>
                                  </tr>
                                  <tr data-bs-toggle="collapse">
                                    <td>
                                      {" "}
                                      <b style={{ fontSize: "15px" }}>
                                        Unesite broj komada:
                                      </b>
                                    </td>
                                    <td>
                                      <input
                                        style={{ width: "100%" }}
                                        type="number"
                                        placeholder="kom."
                                        min={0}
                                        onChange={(e) => {
                                          setNoOfAce(Number(e.target.value));
                                          console.log(noOfAlfred, noOfAce);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <br />
                    <br />
                  </div>
                }
              />
            </Row>
          </Col>
          {sideTable}
          <br />
          <br />
          <br />
          <Col md={9}>
            <Row style={{ paddingLeft: "10px" }}>
              <Card
                content={
                  <div style={{ padding: "8px" }}>
                    <h1 style={{ background: "#f2f2f2" }}>
                      Software | Način plaćanja
                    </h1>
                    <div
                      style={{
                        paddingRight: "6%",
                        paddingLeft: "6%",
                        textAlign: "justify",
                      }}
                    >
                      Softver u samouslužnoj kasi predstavlja aplikaciju koja
                      komunicira sa postojećim POS softverom za fiskalizaciju i
                      izdavanje računa, služi za prikaz informacija za korisnika
                      samouslužne kase i kontroliše periferne uređaje
                      samouslužne kase, poput kamere, inteligentnog svetla i
                      sigurnosne vage. Softver obuhvata i praćenje postavljenih
                      KPI-jeva u različitim periodima na svim samouslužnim
                      kasama u sistemu, kao i aplikaciju za praćenje live
                      aktivnosti na samouslužnoj kasi. U softversku licencu
                      uključeni su pomenuti SCO softver i CDM i SeeYourSCO
                      softver putem kojih je moguće praćenje KPI-jeva. Pored
                      toga, uključeno je i održavanje ovih softvera, dok je
                      licenca za Attendat station softver za praćenje live
                      aktivnosti na uređaju opciona.
                    </div>
                    <br />
                    <div>
                      Ukoliko želite softver za nadgledanje, potrebno je da
                      upišete broj objekata u okviru kojih biste implementirali
                      odabrane uređaje.
                      (Attendant station softver)
                      <br />
                      <br />
                      <Row>
                        <b>Da li želite softver za nadgledanje?</b>
                      </Row>
                      Da&emsp;
                      <input
                        checked={nadgledanjeDa}
                        type="checkbox"
                        name="Da"
                        onChange={(e) => {
                          switchNadogradnja(e);
                        }}
                      />
                      &emsp; Ne&emsp;
                      <input
                        checked={nadgledanjeNe}
                        type="checkbox"
                        name="Ne"
                        onChange={(e) => {
                          switchNadogradnja(e);
                        }}
                      />
                      <Row>{nadgledanjeInput}</Row>
                    </div>

                    <br />
                    <br />
                    <Row>
                      <Col md={6}>
                        <div class="container shadow py-2">
                          <div class="table-responsive">
                            <table class="table accordion">
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      background: "#f2f2f2",
                                      fontSize: "17px",
                                      textAlign: "center",
                                    }}
                                    colSpan={2}
                                    scope="row"
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div
                                        data-bs-toggle="collapse"
                                        data-bs-target="#m1"
                                      >
                                        Opcija 1: Godišnje plaćanje po uređaju
                                        {"     "}
                                        &emsp;
                                        <i class="bi bi-chevron-down" />
                                        &emsp;
                                      </div>
                                      <div>
                                        <input
                                          type="radio"
                                          value="opcija1"
                                          name="opcijaPlacanja"
                                          onChange={() => setOpcijaPlacanja("Godisnje placanje")}
                                        />
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr
                                  class="collapse accordion-collapse"
                                  id="m1"
                                  data-bs-parent=".table"
                                >
                                  <td
                                    colspan="5"
                                    style={{
                                      fontSize: "12px",
                                      textAlign: "justify",
                                    }}
                                  >
                                    {" "}
                                    Godišnje plaćanje obuhvata jednokratni deo
                                    licence koji se plaća samo u prvoj godini,
                                    kao i godišnju licencu i održavanje koji se
                                    plaćaju svake godine za godinu dana unapred,
                                    po SCO uređaju. Softverska licenca za
                                    nadgledanje je opciona, plaća se
                                    jednokratno, samo u prvoj godini, i ne
                                    zavisi od broja uređaja, već od broja
                                    objekata.{" "}
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  data-bs-toggle="collapse"
                                  data-bs-target="#r1"
                                >
                                  <td>Prva godina</td>
                                  <td>
                                    <b>{roundToTwo((1190 * discount))}€</b>{" "}
                                    <i class="bi bi-chevron-down"></i>
                                  </td>
                                </tr>
                                <tr
                                  class="collapse accordion-collapse show"
                                  id="r1"
                                  data-bs-parent=".table"
                                >
                                  <td>
                                    <p>Jednokratna licenca:</p>
                                    <p>Godišnja licenca:</p>
                                    <p>Održavanje uređaja:</p>
                                    <p>Softverska licenca za nadgledanje jednokratno:</p>
                                  </td>
                                  <td>
                                    <p>{roundToTwo((590 * discount))}€</p>
                                    <p>{roundToTwo((0 * discount))}€</p>
                                    <p>{roundToTwo((600 * discount))}€</p>
                                    <p><b>+{roundToTwo((180 * discount) * brojObjekata)}€</b></p>
                                  </td>
                                </tr>
                                <tr
                                  data-bs-toggle="collapse"
                                  data-bs-target="#r2"
                                >
                                  <td>Druga godina</td>
                                  <td>
                                    {" "}
                                    <b> {roundToTwo((718 * discount))}€ </b>
                                    <i class="bi bi-chevron-down"></i>
                                  </td>
                                </tr>
                                <tr
                                  class="collapse accordion-collapse"
                                  id="r2"
                                  data-bs-parent=".table"
                                >
                                  <td>
                                    <p>Jednokratna licenca:</p>
                                    <p>Godišnja licenca:</p>
                                    <p>Održavanje uređaja:</p>
                                    <p>Softverska licenca za nadgledanje:</p>
                                  </td>
                                  <td>
                                    <p>0,00€</p>
                                    <p>{roundToTwo((118 * discount))}€</p>
                                    <p>{roundToTwo((600 * discount))}€</p>
                                    <p>0,00€</p>
                                  </td>
                                </tr>
                                <tr
                                  data-bs-toggle="collapse"
                                  data-bs-target="#r3"
                                >
                                  <td>Treća godina</td>
                                  <td>
                                    {" "}
                                    <b> {roundToTwo((718 * discount))}€ </b>
                                    <i class="bi bi-chevron-down"></i>
                                  </td>
                                </tr>
                                <tr
                                  class="collapse accordion-collapse"
                                  id="r3"
                                  data-bs-parent=".table"
                                >
                                  <td>
                                    {" "}
                                    <p>Jednokratna licenca:</p>
                                    <p>Godišnja licenca:</p>
                                    <p>Održavanje uređaja:</p>
                                    <p>Softverska licenca za nadgledanje:</p>
                                  </td>
                                  <td>
                                    <p>0,00€</p>
                                    <p>{roundToTwo((118 * discount))}€</p>
                                    <p>{roundToTwo((600 * discount))}€</p>
                                    <p>0,00€</p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div class="container shadow py-2">
                          <div class="table-responsive">
                            <table class="table accordion">
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      background: "#f2f2f2",
                                      fontSize: "17px",
                                      textAlign: "center",
                                    }}
                                    colSpan={2}
                                    scope="row"
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div
                                        data-bs-toggle="collapse"
                                        data-bs-target="#m2"
                                      >
                                        Opcija 2: Mesečno plaćanje po uređaju
                                        &emsp;
                                        <i class="bi bi-chevron-down" />
                                        &emsp;
                                      </div>
                                      <div>
                                        <input
                                          type="radio"
                                          value="opcija2"
                                          name="opcijaPlacanja"
                                          onChange={() => setOpcijaPlacanja("Mesecno placanje")}
                                        />
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr
                                  class="collapse accordion-collapse"
                                  id="m2"
                                  data-bs-parent=".table"
                                >
                                  <td
                                    colspan="5"
                                    style={{
                                      fontSize: "12px",
                                      textAlign: "justify",
                                    }}
                                  >
                                    Mesečno plaćanje obuhvata softversku licencu
                                    i održavanje koji se plaćaju mesečno, po SCO
                                    uređaju. Softverska licenca za nadgledanje
                                    je opciona, plaća se mesečno, i ne zavisi od
                                    broja uređaja, već od broja objekata.
                                    {" "}
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  data-bs-toggle="collapse"
                                  data-bs-target="#r4"
                                >
                                  <td>Mesečni troškovi</td>
                                  <td>
                                    {" "}
                                    <b>{roundToTwo((80 * discount))}€</b>
                                    <i class="bi bi-chevron-down"></i>
                                  </td>
                                </tr>

                                <tr
                                  class="collapse accordion-collapse show"
                                  id="r4"
                                  data-bs-parent=".table"
                                >
                                  <td>
                                    <p>Jednokratna licenca:</p>
                                    <p>Godišnja licenca:</p>
                                    <p>Održavanje uređaja:</p>
                                    <p>Softverska licenca za nadgledanje:</p>
                                  </td>
                                  <td>
                                    <p>0,00€</p>
                                    <p>{roundToTwo((30 * discount))}€</p>
                                    <p>{roundToTwo((50 * discount))}€</p>
                                    <p><b>+{roundToTwo((8 * discount) * brojObjekata)}€</b></p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <br />
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <br />
                  </div>
                }
              />
            </Row>
          </Col>
          <br />
          <br />
          <br />

          <Col md={9}>
            <Row style={{ paddingLeft: "10px" }}>
              <Card
                content={
                  <div style={{ padding: "8px" }}>
                    <h1 style={{ background: "#f2f2f2" }}>Integration</h1>
                    <div
                      style={{
                        paddingLeft: "8%",
                        paddingRight: "8%",
                        textAlign: "justify",
                      }}
                    >
                      Integracija obuhvata povezivanje SCO i POS softvera,
                      vođenje projekta od početka pa sve do kraja testnog
                      perioda, konsultacije, radionice, obuke, modelovanje
                      podataka za pravilnu implementaciju SCO, implementaciju
                      poslovnog procesa. Moguća je integracija po SCO jedinici
                      ili jednokratna integracija.
                    </div>
                    <br />
                    <br />
                    <Row>
                      <Col md={6}>
                        <div
                          style={{ fontSize: "10px", background: "#f2f2f2" }}
                        >
                          <b style={{ fontSize: "17px" }}>
                            Opcija 1: Integracija po SCO jedinici{" "}
                          </b>
                          <input
                            type="radio"
                            value="opcija11"
                            name="opcijaIntegracije"
                            onChange={() => setOpcijaIntegracije("pojedinici")}
                          />
                        </div>
                        <br />
                        <div>
                          Minimalna cena za integraciju po jedinci je {roundToTwo((4500 * discount))} €,
                          koja važi za do 3 uređaja, dok je cena integracije za
                          svaki naredni uređaj (od četvrtog) {roundToTwo((1500 * discount))}€.
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        {noOfAce + noOfAlfred <= 3 ? (
                          <b style={{ fontSize: "14px" }}>
                            Iznos za Vaš broj uređaja je : {roundToTwo((4500 * discount))}€
                          </b>
                        ) : (
                          <div>
                            {noOfAce + noOfAlfred > 3 ? (
                              <b>
                                {" "}
                                Iznos za Vaš broj uređaja je :{" "}
                                {roundToTwo((noOfAce + noOfAlfred) * (1500 * discount))}€{" "}
                              </b>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        )}

                        <br />
                        <br />
                        <br />
                      </Col>
                      <Col md={6}>
                        <div
                          style={{ fontSize: "10px", background: "#f2f2f2" }}
                        >
                          <b style={{ fontSize: "17px" }}>
                            Opcija 2: Jednokratna integracija{" "}
                          </b>
                          <input
                            type="radio"
                            value="opcija22"
                            name="opcijaIntegracije"
                            onChange={() => setOpcijaIntegracije("jednokratno")}
                          />
                        </div>
                        <br />
                        <div style={{ textAlign: "justify" }}>
                          Jednokratna integracija se preporučuje korisnicima
                          koji planiraju implementaciju većeg broja uređaja u
                          budućnosti, s obzirom da njena cena ne zavisi od broja
                          uređaja, ni perioda kada će dodatni uređaji biti
                          implementirani. Jednokratna integracija pokriva kako
                          pilot projekat, tako i svaku narednu implementaciju
                          dodatnih uređaja.
                        </div>
                        <br />
                        <b style={{ fontSize: "14px" }}>
                          {" "}
                          Predložena cena: {sugestedPrice}€{" "}
                        </b>
                      </Col>
                    </Row>
                    <br />
                  </div>
                }
              />
            </Row>
          </Col>

          <br />
          <br />
        </Container>
      </div>
    </div>
  );
}
export default MainPage;
