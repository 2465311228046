import React, { useEffect, useState } from 'react';
import { Row, Col } from "react-bootstrap";
import { useAuth } from '../context/auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('Sana 2020');
  const [auth, setAuth] = useAuth("")
  const navigate = useNavigate();

  function checkSize() {
    if (window.innerWidth < 1000) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Aplikacija nije optimizovana za mobilne telefone, pokušajte na računaru ili laptopu!',
        showConfirmButton: false,
        timer: 3000
      });
      return true;
    } else {
      return false
    }
  }
  useEffect(() => {
    if (checkSize()) {
      nextPage()
    }
  }, [])
  async function handleContinue(e) {
    // Ovo sranje ovde znaci da ne osvezava stranicu kad se posalje forma na enter dugme 
    // na sta sam bacio 15 minuta sunce ti... zab za ovo 
    e.preventDefault();
    // Proimenio sam malo login kako radi,
    // da ne pravimo Jani sppecialan mail, 
    // za svaki mail koji se zavrsava sa galeb.com on ce pitati za pass
    // Takodje izmena logina kod provera da ne bi imali nested zajebanciju stavio sam odmah da vrati poruku ako ne valja mail 
    if (email == '' || !email.includes('@')) {
      //Mail je neispravan, baci swall alert
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Unesite mail adresu',
        showConfirmButton: false,
        timer: 3000
      });
      return;
    }
    let trident = {
      email: email,
      password: password
    }
    if (checkSize()) {
      return;
    }
    //Mail je prosao validaciju i moze da se ide dalje
    if (email.endsWith('galeb.com')) {
      const { value: adminPass } = await Swal.fire({
        input: 'textarea',
        inputLabel: 'Lozinka',
        inputPlaceholder: 'Unesite lozinku',
        inputAttributes: {
          'aria-label': 'Unesite lozinku'
        },
        showCancelButton: true,
        customClass: {
          cancelButton: 'Otkaži',
          confirmButton: 'Unesi'
        }
      })
      if (adminPass) {
        trident = {
          email: email,
          password: adminPass
        }
      }
    }

    axios.post(`/api/users/login`, trident)
      .then(res => {
        setAuth({
          ...auth,
          isAdmin: res.data.isAdmin,
          email: res.data.email,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem('auth', JSON.stringify(res.data));
        console.log(res.data.isAdmin);
        if (res.data.isAdmin === 'Yes') {
          navigate('/MasterPage');
        } else {
          navigate('/MainForm');
        }

      })
      .catch(error => {
        console.log(error);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: error.response.data.error,
          showConfirmButton: false,
          timer: 3000
        });
      });

  }
  const nextPage = () => {
    if (auth.email != null) {
      if (auth.isAdmin == "Yes") {
        navigate("/MasterPage")
      } else {
        navigate("/MainForm")
      }
    }
  }

  return (
    <div className="content">
      <div>
        <Row>
          <Col>
            <div className="wrapper">
              <form onSubmit={(e) => { handleContinue(e) }}>
                <h1>Prijava</h1>
                <div className="input-box">
                  <input
                    type="text"
                    placeholder="Username"
                    name="email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={(e) => { if (e.key == 'Enter') { handleContinue(e) } }}
                  />
                  <i className="bx bxs-user"></i>
                </div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => handleContinue(e)}
                >
                  Nastavi
                </button>
                <br />
                <br />
                <br />
                <div style={{ textAlign: "center" }}>
                  <p>
                    Ne možete da pristupite? <a href="/">Prijavi problem</a>
                  </p>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Login;