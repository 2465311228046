// Import useEffect kuke
import React, { useState, useEffect } from 'react';
import { Table, Row, Col } from "react-bootstrap";
import { useAuth } from '../context/auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Card from './Card';
import Swal from 'sweetalert2';

function MasterPage() {
  const [users, setUsers] = useState([])
  const [uEmail, setUEmail] = useState('')
  const [uName, setUName] = useState('')
  const [uDiscount, setUDiscount] = useState('')
  const [uSugestedPrice, setUsugestedPrice] = useState('')
  const [auth, setAuth] = useAuth()
  const navigate = useNavigate();

  //Logout finction
  const logout = () => {
    // user data stored in local storage (cookie)
    // removes all data from local storage and reloads page
    localStorage.clear();
    // this part reloads page, and since local storagge is empty it sends user to login page
    window.location.reload();
  }

  // Definisanje funkcije getUsers van useEffect bloka
  const getUsers = async () => {
    try {
      const response = await axios.post(`/api/users/getUsers/`);
      setUsers(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log(auth)
    if (auth.isAdmin == false || auth.isAdmin == null) {
      navigate('/')
    }
    // Pozivamo funkciju za dobijanje korisnika nakon prvog renderovanja
    getUsers();
  }, []);

  const removeUserFromList = (id) => {
    Swal.fire({
      title: "Brisanje korisnika",
      text: "Sigurno želite da obrišete korisnika?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Ne",
      confirmButtonText: "Da, obriši!"
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`/api/users/deleteUser/`, { 'id': id })
          .then(response => {
            Swal.fire({
              title: "Obrisan!",
              text: "Korisnik je obrisan.",
              icon: "success"
            });
            getUsers();
          })
          .catch(err => {
            Swal.fire({
              title: "Nije obrisan!",
              text: "Nešto ne štima ovde.",
              icon: "error"
            });
          })
      }
    });
  }

  // Funkcija za unos novog korisnika
  const insertNewClient = () => {
    // Implementacija funkcije za unos novog korisnika...
    const payload = {
      email: uEmail,
      name: uName,
      discount: uDiscount,
      sugestedPrice: uSugestedPrice
    };
    if (uEmail == '' || uName == '' || uDiscount == '' || uSugestedPrice == '') {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Forma pravilno nije popunjena",
        showConfirmButton: false,
        timer: 3000
      });
      return;
    }
    axios.post(`/api/users/insertNewUser/`, payload)
      .then(response => {
        // Resetujemo stanje za unos novog korisnika
        setUEmail('');
        setUName('');
        setUDiscount('');
        setUsugestedPrice('');
        // Nakon uspešnog unosa novog korisnika, pozivamo funkciju za dobijanje svih korisnika kako bismo ažurirali listu
        getUsers();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Korisnik je unet!",
          showConfirmButton: false,
          timer: 2000
        });
      })
      .catch(err => {
        console.log(err)
      })
  }

  // Definišemo JSX za unos novog korisnika
  let formPart = (
    <tr>
      <td>
        <input type="text" value={uEmail} onChange={(e) => setUEmail(e.target.value)} />
      </td>
      <td>
        <input type="text" value={uName} onChange={(e) => setUName(e.target.value)} />
      </td>
      <td>
        <input type="text" value={uDiscount} onChange={(e) => setUDiscount(e.target.value)} />
      </td>
      <td>
        <input type="text" value={uSugestedPrice} onChange={(e) => setUsugestedPrice(e.target.value)} />
      </td>
      <td>
        <button
          type="button"
          className="btn-fill btn btn-sm btn-success"
          onClick={() => insertNewClient()}
        >
          Unesi
        </button>
      </td>
      <td />
    </tr>
  );

  // Definišemo JSX za prikaz korisnika
  let tableBody = (
    <tr>
      <td>Nema podataka za prikaz</td>
    </tr>
  )
  if (users) {
    tableBody = users.map((users) => (
      <tr key={users._id}>
        <td>{users.email}</td>
        <td>{users.name}</td>
        <td>{users.discount}</td>
        <td>{users.sugestedPrice}</td>
        <td>
          <button
            type="button"
            className="btn-fill btn btn-sm btn-danger"
            onClick={() => removeUserFromList(users._id)}
          >
            Obriši
          </button>
        </td>
      </tr>
    ))
  }

  return (
    <div className="content" >
      <Row className = "justify-content-end">
        <Col md={3}>
          {auth.email}
          &emsp;
          <button type='button' className="btn-fill btn btn-sm btn-danger" style = {{}} onClick={() => { logout() }}>Izloguj se</button>
        </Col>
      </Row>
      <Row>
        <div style={{ padding: '1% 1% 1% 1%' }}>
          <Card
            title="Korisnici"
            category="Informacije o klijentima zainteresovanim za SCO"
            ctTableFullWidth
            ctTableResponsive
            content={
              <Table striped hover id="table-to-xls" >
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Naziv</th>
                    <th>Procenat</th>
                    <th>Preporučena cena</th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody>{formPart}{tableBody}</tbody>
              </Table>
            }
          />
        </div>
      </Row>

    </div>
  )
}

export default MasterPage;
