import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useAuth } from "../context/auth";
import axios from "axios";
import Card from "./Card";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function MailSend() {
  const [file, setFile] = useState([]);
  const [counter, setCounter] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    let results = [];
    console.log(file);
    for (let i = 0; i < file.length; i++) {
      const formData = new FormData();
      const fileNo = `file-${0}`;
      const fileValue = file[i];
      const fileName = file[i].name;
      console.log(fileNo, fileValue);
      formData.append(fileNo, fileValue, fileName);
      axios.post(`/api/users/mailSendSpam/`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          results.push(response.data);
        })
        .catch((err) => {
          results.push(fileName + "Not sent");
        });
    }
  };

  return (
    <div className="content">
      <Container fluid>
        <Col md={4}>
          <Card
            title="Uvoz"
            category="Slanje mailova PDF"
            ctTableFullWidth
            ctTableResponsive
            content={
              <div className="container">
                <div className="row">
                  <form
                    onSubmit={(e) => onSubmit(e)}
                    className="justify-content-center"
                  >
                    <Row>
                      <input
                        type="file"
                        onChange={(e) => {
                          setFile([...e.target.files]);
                          console.log(e.target.files);
                        }}
                        name="file"
                        accept=".pdf"
                        multiple
                      />
                    </Row>
                    <Row>
                      <Col md={6}>
                        <button
                          className="btn btn-primary btn-fill col-12"
                          type="submit"
                        >
                          Unesi
                        </button>
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
            }
          />
        </Col>
      </Container>
    </div>
  );
}

export default MailSend;
